<template>
  <div>
    <FilterTessereSpecialiEconomato
      @getEconomatoList="getEconomatoListResetPage"
      @resetFilters="resetFilters"
    />
    <TableTessereSpecialiEconomato
      @getEconomatoList="getEconomatoList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterTessereSpecialiEconomato from "@/components/components-fit/gestione/economato/FiltriTessereSpecialiEconomato.vue";
import TableTessereSpecialiEconomato from "@/components/components-fit/gestione/economato/TableTessereSpecialiEconomato.vue";

export default defineComponent({
  name: "tessere-speciali-economato",
  components: {
    FilterTessereSpecialiEconomato,
    TableTessereSpecialiEconomato,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);

    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionaleTessereSpeciali
    );
    const idPersona = computed(() => store.getters.idTesseratoTessereSpeciali);

    const startDateEmissione = computed(
      () => store.getters.dataEmissioneDaTessereSpeciali
    );
    const endDateEmissione = computed(
      () => store.getters.dataEmissioneATessereSpeciali
    );
    const startDateApprovazione = computed(
      () => store.getters.dataApprovazioneDaTessereSpeciali
    );
    const endDateApprovazione = computed(
      () => store.getters.dataApprovazioneATessereSpeciali
    );

    const rowsToSkip = computed(() => store.getters.rowsToSkipTessereSpeciali);
    const fetchRows = computed(() => store.getters.fetchRowsTessereSpeciali);
    const sortColumn = computed(() => store.getters.sortColumnTessereSpeciali);
    const sortOrder = computed(() => store.getters.sortOrderTessereSpeciali);

    const getEconomatoList = () => {
      const localKeys = {
        stagione: anno.value,
        id_Comitato: idComitatoRegionale.value,
        data_Ins_Da: startDateEmissione.value,
        data_Ins_A: endDateEmissione.value,
        data_Appr_Da: startDateApprovazione.value,
        data_Appr_A: endDateApprovazione.value,
        id_Persona: idPersona.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ECONOMATO_TESSERE_SPECIALI_LIST,
        itemName: "economato_tessere_speciali_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersTessereSpeciali");
      getEconomatoList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedeconomato_tessere_speciali_list")
    );
    const getEconomatoListResetPage = () => {
      if (!loaded.value) return;
      store.commit("resetRowsToSkipTessereSpeciali");
      getEconomatoList();
    };
    getEconomatoList();

    return {
      getEconomatoList,
      resetFilters,
      getEconomatoListResetPage,
    };
  },
});
</script>
